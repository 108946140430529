.dotted {
  display: block !important;
  padding-top: 20px;
  padding-right: 200px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px dotted;
  border-radius: 10px;
}

.files-wrapper {
  width: 500px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.eds-modal {
  /* height: 400px !important; */
}
