.search-filter-item {
  border-radius: var(--eds-radii-4);
  cursor: pointer;
  margin: 0 4px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  background-color: var(--eds-color-grey-3);
  border-radius: 2px;
  min-width: 0px;
  box-sizing: border-box;
}

.vertical-divider {
  width: 1px;
  height: 40px;
  background-color: black;
  margin-left: 5px;
  margin-right: 5px;
}
