.app-wrapper {
  display: flex;
  flex-direction: row;
}

.app-body {
  background-color: #f5f5f5;
  width: calc(100% - 96px);
  height: 100%;
  margin-left: 96px;
}
