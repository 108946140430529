.print-list-header-row:hover {
  transition: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.print-list-header {
  cursor: pointer;
  white-space: nowrap;
}

.empty-row {
  width: 100%;
  height: 80px;
  border-top: 1px solid var(--eds-color-grey-3);
}

.empty-row:hover {
  background-color: rgba(0, 0, 0, 0) !important;
  pointer-events: none !important;
  transition: none !important;
}

.scrollable-table {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  overflow-x: scroll;
}

.scrollable-table table {
  width: auto;
}

.scrollable-table thead tr {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.scrollable-table td,
.scrollable-table th {
  /* width: 150px; */
  white-space: nowrap;
  height: 48px;
  text-align: left;
  overflow: auto;
}

/* .scrollable-table td:last-child {
  width: 80px;
  height: 80px;
  display: flex;
  position: absolute;
  right: calc(0px + 48px);
  background-color: white;
  box-shadow: -10px 0px 100px var(--eds-color-grey-3);
} */

.scrollable-table tbody {
  overflow-y: scroll;
}
