.modal-content-wrapper {
  width: 370px;
}

.print-steps-wrapper {
  font-weight: bold !important;
}

.print-message-wrapper {
  white-space: normal;
}
